import Header from '../../components/Header/Header.js';

const Admin = () => {
  return (
    <div className="admin">
      <Header />
      <div>
        <div>Welcome to admin page</div>
      </div>
    </div>
  );
}

export default Admin;
